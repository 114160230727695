<template>
  <div style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="重置用户操作密码"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div style="min-height: 60vh; width: 40%;margin-top: 10vh;">
          <el-form ref="resetform" :model="userInfo" label-width="100px" :rules="resetFrom">
            <el-form-item label="密码" prop="operationPassword">
              <el-input v-model.trim="userInfo.operationPassword" show-password placeholder="请输入要设置的密码" :disabled="disabled"></el-input>
              <el-tooltip placement="right" style="position: absolute; right: -30px">
                <div slot="content">密码为6~16个英文字母、数字、符号组成！</div>
                <el-button type="text"><i class="el-icon-question" style="font-size: 20px"></i></el-button>
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="openEdit" v-show="showBtn" plain>{{ btnText }}</el-button>
              <el-button type="primary" @click="onSubmit('resetform')" v-show="showSaveBtn">确认修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '../../../components/HospitalDomain/User'
import bread from '../../../components/common/breadcrumb'
export default {
  name: 'ResetPwd',
  components: {
    bread,
  },
  inject: ['reload'],
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      UserDomain: user,
      userInfo: {
        operationPassword: '',
      },
      disabled: true,
      showSaveBtn: false,
      showBtn: true,
      btnText: '修改密码',
      resetFrom: {
        operationPassword: [
          { required: true, message: '请输入要设置的密码', trigger: 'blur' },
          {
            pattern: /^(?![\s\u4e00-\u9fa5]+$).{6,16}$/,
            message: '密码为6~16个英文字母、数字、符号组成！',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    openEdit() {
      if (this.btnText == '取消') {
        this.btnText = '修改密码'
        this.disabled = true
        this.showSaveBtn = false
        this.$refs['resetform'].resetFields()
      } else {
        this.btnText = '取消'
        this.disabled = false
        this.showSaveBtn = true
      }
    },
    onSubmit(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.UserDomain.OrgResetPassword(
            _this.userInfo,
            function () {
              _this.$message({
                message: '设置成功',
                type: 'success',
              })
              _this.reload()
            },
            function (error) {
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/*content*/
</style>
